exports.components = {
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-collections-contentful-developer-collection-id-js": () => import("./../../../src/pages/collections/{ContentfulDeveloperCollection.id}.js" /* webpackChunkName: "component---src-pages-collections-contentful-developer-collection-id-js" */),
  "component---src-pages-collections-index-js": () => import("./../../../src/pages/collections/index.js" /* webpackChunkName: "component---src-pages-collections-index-js" */),
  "component---src-pages-contribute-index-js": () => import("./../../../src/pages/contribute/index.js" /* webpackChunkName: "component---src-pages-contribute-index-js" */),
  "component---src-pages-developers-contentful-developer-id-js": () => import("./../../../src/pages/developers/{ContentfulDeveloper.id}.js" /* webpackChunkName: "component---src-pages-developers-contentful-developer-id-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-scans-contentful-scan-id-js": () => import("./../../../src/pages/scans/{ContentfulScan.id}.js" /* webpackChunkName: "component---src-pages-scans-contentful-scan-id-js" */)
}

